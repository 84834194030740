import { graphql, useStaticQuery } from "gatsby";
import { ArrayElement } from "@src/interface/utils";
import usePageLang from "../usePageLang";

export type AllStoreQueryNode = ArrayElement<
  Queries.UseAllStoreQuery["allStore"]["nodes"]
>;

export enum StoreType {
  SHOP = 0,
  CORNER = 1,
  TRAVEL_RETAIL = 2,
}
const useAllStore = (): { data: AllStoreQueryNode[] } => {
  const currentLang = usePageLang();

  //
  const { allStore } = useStaticQuery<Queries.UseAllStoreQuery>(graphql`
    query UseAllStore {
      allStore {
        nodes {
          id_store
          sale_on_web
          active
          titre_seo
          accept_vae
          accept_shipping
          comming_soon
          enable_invoice
          allow_use_jackpot
          force_card_security
          # id_caisse
          id_postcode
          # id_neolane
          postcodes
          postcode
          type_shop
          gmap_latitude
          gmap_longitude
          template
          is_comein
          lang
          id_store_lang
          uri
          shopUri
          name
          address
          address2
          postcode
          city
          phone
          # phone_pricing
          # display_avis_verifie
          # avg_avis_verifie
          # count_avis_verifie
          factorized_options
          benefits {
            id_store_benefit
          }
          #          benefit {
          #            primary
          #            secondary
          #          }
          breadcrumb {
            name
            url
          }
          # faq {
          #   answer
          #   question
          # }
          opening_hours {
            time_opening_am
            time_closing_am
            is_closed_am
            time_opening_pm
            time_closing_pm
            is_closed_pm
          }
          time {
            vae {
              now {
                is_closed_am
                is_closed_pm
                time_deb_am
                time_end_am
                time_deb_pm
                time_end_pm
              }
              differ {
                is_closed_am
                is_closed_pm
                time_deb_am
                time_end_am
                time_deb_pm
                time_end_pm
              }
            }
            vad {
              now {
                is_closed_am
                is_closed_pm
                time_deb_am
                time_end_am
                time_deb_pm
                time_end_pm
              }
              differ {
                is_closed_am
                is_closed_pm
                time_deb_am
                time_end_am
                time_deb_pm
                time_end_pm
              }
            }
          }
        }
      }
    }
  `);
  return {
    data: allStore.nodes.filter((node) => node.lang === currentLang),
  };
};

export default useAllStore;
